import React from 'react'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import Heading from 'components/core/Heading'

export default props => (
  <Layout display='flex'>
    <Head title='404' />
    <Heading m='auto'>음, 페이지를 찾지 못했어요.</Heading>
  </Layout>
)
